/* Jquery */
const $ = require('jquery');
global.$ = global.jQuery = $;

const bootstrap = window.bootstrap = require('bootstrap');
import '../scss/vendor.scss';

/* Toastr */
const toastr = require('toastr');
toastr.options = {
    "debug": false,
    "positionClass": "toast-top-right",
    "onclick": null,
    "fadeIn": 300,
    "fadeOut": 1000,
    "timeOut": 5000,
    "extendedTimeOut": 1000
};
global.toastr = toastr;

const moment = require('moment');
require('moment/locale/fr');

global.moment = moment;
moment.locale('fr');

